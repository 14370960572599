// BitoPro Landing Page Custom scripts
$(document).ready(function (e) {

  // Routing
  if (window.location.href.split('#')[1] === '/privacy') {
    switchTo('privacy');
  } else if (window.location.href.split('#')[1] === '/terms') {
    switchTo('terms');
  }
  function switchTo(view) {
    $('.wrapper').removeClass('terms privacy').addClass(view);
    $('html, body').animate({
      scrollTop: 0
    }, 200, function() {
      $('.wrapper .navbar li.active').removeClass('active');
    });
    window.history.pushState({
      url: view
    }, '', '/#/' + view);
  }
  $('#privacy').bind('click', function(e) {
    switchTo('privacy');
    e.preventDefault();
  });
  $('#terms').bind('click', function(e) {
    switchTo('terms');
    e.preventDefault();
  });
  $(window).on('hashchange', function(e) {
    if (e.target.location.hash === '#/privacy') {
      switchTo('privacy');      
    } else if (e.target.location.hash === '#/terms') {
      switchTo('terms');
    }
  });

  function getCurrentUTC() {
    return moment.utc();
  }

  // Detect mobile
  var isMobile = false;
  if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
    isMobile = true;
    $('.wrapper').addClass('mobile');
  }

  var stages = [
    moment.utc('2018-05-02T12:00:00+08:00'),
    moment.utc('2018-05-12T12:00:00+08:00'),
    moment.utc('2018-05-22T12:00:00+08:00'),
    moment.utc('2018-05-31T12:00:00+08:00'),
  ];
  var icoDate = stages[0];
  var currentStage = 0;

  // Bonus
  var bonus0 = 6000;
  var now = getCurrentUTC();
  var diffStages = [0, 0, 0, 0]
  for (var i = 0; i < stages.length; i++) {
    diffStages[i] = now - stages[i];
    if ( (i + 1) < stages.length) {
      var stageEq = '#stage-' + (i + 1) + ' .eq-function';
      $(stageEq).text('1 ETH = ' + $(stageEq).data('value').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' BITO' );
    }
  }

  // Progress bar
  function numberWithCommas(amount) {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  // Close ICO
  function closed() {
    $('.wrapper').addClass('closed');
    $('.ico-state .title').text('');
    $('#price-base').text($.i18n('closed'));
    $('#ico-progress .amount').text($.i18n('sold_out'));
  }

  setTimeout(closed, 0);

  // i18n setup
  function setLocale(locale) {
    $.i18n({
      locale: locale,
      fallback: 'en'
    });
    $('body').i18n();

    var bitoproLocale = 'zh-tw'
    switch (locale) {
      case 'en':
        $('.wrapper').removeClass('cn').addClass('en');
        bitoproLocale = 'en'
        break;
      case 'zh-cn':
        $('.wrapper').removeClass('en').addClass('cn');
        bitoproLocale = 'cn'
        break;
      default:
        $('.wrapper').removeClass('en cn');
        break;
    }
    $('.ico-notice').html($.i18n('ico_notice_1'));
    $('#bounty-winners').html($.i18n('bounty_plan_winners'));
    $('.join-ico').attr('href', 'https://www.bitopro.com/token_exchange/bito/intro?locale='+bitoproLocale);
  }
  function switchLang(e) {
    e.preventDefault();
    var locale = $(this).data('locale');
    $('#selectedLang').text($(e.target).text());
    setLocale(locale);
  }
  $.i18n().load({
    'en': 'i18n/en.json',
    'zh-tw': 'i18n/zh-tw.json',
    'zh-cn': 'i18n/zh-cn.json',
  }).done(function () {
    var userLang = navigator.language || navigator.userLanguage; //'en-US';
    var customLocale = (window.location.hash && window.location.hash.indexOf('/') !== 1 && window.location.hash.replace('#', '')) || userLang.toLowerCase() || 'zh-tw';
    if (customLocale.indexOf('en-') === 0) customLocale = 'en';
    else if(customLocale.includes('zh-')){
      customLocale = customLocale.includes('cn') ? 'zh-cn' : 'zh-tw'
    } else customLocale = 'zh-tw'
    setLocale(customLocale);
    $('#selectedLang').text($('[data-locale="' + customLocale + '"]').text());
    $('.navbar .dropdown-menu a').on('click', switchLang);
  });

  // Highlight the top nav as scrolling
  $('body').scrollspy({
    target: '.navbar-fixed-top',
    offset: 120
  });

  // Page scrolling feature
  $('a.page-scroll').bind('click', function (event) {
    var link = $(this);
    if (window.history.state &&
      (window.history.state.url === 'privacy' || window.history.state.url === 'terms')
    ) {
      $('.wrapper').removeClass('privacy terms');
      window.history.replaceState({ url: '' }, '', '/');
    }
    $('html, body').stop().animate({
      scrollTop: $(link.attr('href')).offset().top - 70
    }, 500);
    event.preventDefault();
  });

});

// Activate WOW.js plugin for animation on scrol
new WOW().init();